import { Button, ButtonLink, ButtonLinkProperties, ButtonProperties } from 'components/Button';
import styles from './ButtonGroup.module.css';
import cx from 'classnames';

interface Properties {
  buttons?: (ButtonLinkProperties | ButtonProperties)[];
  center?: boolean;
  dropdownGroup?: boolean;
  spaceBetween?: boolean;
}

export const ButtonGroup = ({ buttons, center = false, spaceBetween = false, dropdownGroup = false }: Properties) => (
  <div
    className={cx(styles.container, {
      [styles.center]: center,
      [styles.spaceBetween]: spaceBetween,
      [styles.dropdownGroup]: dropdownGroup,
    })}>
    {buttons?.map((buttonProperties, index) =>
      isButtonLink(buttonProperties) ? (
        <ButtonLink key={index} {...buttonProperties} />
      ) : (
        <Button key={index} {...buttonProperties} />
      )
    )}
  </div>
);

function isButtonLink(
  buttonProperties: ButtonLinkProperties | ButtonProperties
): buttonProperties is ButtonLinkProperties {
  return 'href' in buttonProperties;
}
